import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import SettingsProvider from "./contexts/SettingsContext";
import reduxStore from "./redux/reduxStore";
import { HelmetProvider } from 'react-helmet-async'
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <Provider store={reduxStore}>
                <SettingsProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </SettingsProvider>
            </Provider>
        </HelmetProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
